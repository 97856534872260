import  Navbar from "../Components/Elements/Navbar"
import Hero from "../Components/Elements/Hero"
import FeaturesSection from "../Components/Elements/Features"
import HowItWorks from "../Components/Elements/Howitworks"
import ReviewsSection from ".././Components/Elements/Reviews"
import Footer from "../Components/Elements/Footer"


export default function Home () {
    return(
        <>
         <Navbar />
         <Hero />
         <HowItWorks />
         <ReviewsSection />
         <FeaturesSection />
         <Footer />
        </>
    )
}