import React from 'react';
import logowhite from "../../Assets/whitebglogo.png"
import qrcode from "../../Assets/qrcodesample.png"

function Footer() {
  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto grid-cols-1 md:grid-cols-4 gap-8 flex justify-around">
        {/* Left Column */}
        <div>
         <img src={logowhite} className='h-52'></img>
        </div>


        {/* Download App */}
        <div className="flex flex-col items-center justify-center">
  <h3 className="font-bold text-lg mb-4">Download the App</h3>
  <img src={qrcode} className="h-32 w-32 rounded-lg" alt="QR Code" />
</div>
</div>

      {/* Footer Bottom */}
      <div className="mt-8 text-center text-gray-600">
        &copy; {new Date().getFullYear()} Numeric Education Pvt. Ltd. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
