import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import Home from "../src/Pages/Home"
function App() {
  return (
    <>
    <Home />
    </>
  
  );
}

export default App;
