import React from 'react';

const features = [
  {
    title: 'Get  Exciting Rewards  ',
    description: 'Gain exciting rewards for your dedication to learning! the more you study, the more rewards you get',
    icon: '🏅',
  },
  {
    title: ' Compete With Peers',
    description: 'Compete against friends on the leaderboard!The top 3 positions win amazing  grand rewards every month!',
    icon: '⚔️',
  },
  {
    title: 'Top Quality Content',
    description: 'Our outstanding notes and insightful videos have been carefully curated to elevate your academic perfromance',
    icon: '💡',
  },
  {
    title: 'Engaging Community',
    description: 'Join community and  share your ideas, engage in discussions, study alongside your peers, and exchange knowledge.',
    icon: '🤝',
  },
];

export default function FeaturesSection() {
  return (
    <section className="bg-black text-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12">OUR FEATURES</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div key={index} className="p-6 bg-gray-800 rounded-lg text-center hover:bg-gray-700 transition">
              <div className="text-6xl mb-4">{feature.icon}</div>
              <h3 className="text-2xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
