import React, { useState } from "react";
import Logocomplete from "../../Assets/logocomplete.png"

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex justify-center">
    <nav className="bg-white shadow-md fixed  z-50 pb-3 rounded-3xl bg-opacity-75 mt-3 backdrop-blur-sm w-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0 ">
            <a href="/" className="text-2xl font-extrabold text-blue-500">
              <img className="h-18 w-auto mt-3" src={Logocomplete} alt="Logo" />
            </a>
          </div>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-6 mt-1 ms-3">
          <a href="https://www.cubegonresources.com"> 
            <button type="button" className="border-black rounded-2xl text-black mt-3 h-11 border-2 w-full transition duration-300 ease-in-out hover:scale-105 me-4">
             Study For Free </button> </a>
             <a href="https://www.google.com"> 
            <button type="button" className="border-black rounded-2xl text-black mt-3 h-11 border-2 w-full transition duration-300 ease-in-out hover:scale-105 me-4">
             Download The App </button> </a>
             {/* change link here  */}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-800 hover:text-gray-600 focus:outline-none mt-3 ms-5"
            >
              <svg className="h-8 w-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="flex justify-around mt-2 mb-2">
          <a href="https://www.cubegonresources.com"> 
            <button type="button" className="border-black rounded-2xl text-black mt-3 h-11 border-2 w-full transition duration-300 ease-in-out hover:scale-105 me-4">
             Study For Free </button> </a>
             <a href="https://www.google.com"> 
            <button type="button" className="border-black rounded-2xl text-black mt-3 h-11 border-2 w-full transition duration-300 ease-in-out hover:scale-105 me-4">
             Download The App </button> </a>
             {/* change link here  */}
            
          </div>
        </div>
      )}
    </nav>
    </div>
  );
};

export default Navbar;
