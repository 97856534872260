import React from "react";
import  { MyComponent }  from "../UI/gps"
import { MyComponent2 } from "../UI/aps"

const Hero = () => {
  return (
    <div className=" text-black py-20">
      <div className="container mx-auto px-6 lg:flex lg:items-center lg:justify-between mt-6">
        {/* Left Side: Text Content */}
        <div className="lg:w-1/2 space-y-6">
          <h1 className="text-4xl font-bold lg:text-6xl">
          Learning Just Got More Fun - Earn Rewards for Studying!
          </h1>
          <p className="text-lg lg:text-xl">
          🎓 Study smarter and get rewarded! 🎁 Unlock exclusive perks, earn exciting rewards, and make learning a fun and fulfilling journey. 🚀 Join now and turn your hard work into something extraordinary!
          </p>
          <div className=" space-y-6">
          <MyComponent />
          <MyComponent2 />
          </div>
        </div>

        {/* Right Side: Image */}
        <div className="mt-10 lg:mt-0 lg:w-1/2">
          <img
            className="w-full h-auto rounded-md"
            src="https://cubegon.com/public/assets/img/landing1.png"
            alt="Cubegon Illustrations"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
