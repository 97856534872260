import { GooglePlayButton } from "react-mobile-app-button";

export const MyComponent = () => {
  const APKUrl = "https://www.google.com";
//   change link here 

  return (
    <div>
      <GooglePlayButton
        url={APKUrl}
        theme={"dark"}
        title=""
        className={"custom-style hover:scale-105"}
      />
    </div>
  );
};