import React, { useState, useRef } from 'react';
import db1 from "../../Assets/ms.webp";
import db2 from "../../Assets/rwp.webp";
import db3 from "../../Assets/rp.webp";
import { MyComponent } from "../UI/gps";
import { MyComponent2 } from "../UI/aps";

const HowItWorks = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isFinished, setIsFinished] = useState(false);

  // Ref to scroll to a specific step or section
  const finishSectionRef = useRef(null);

  const nextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(prevStep => prevStep + 1);
    } else {
      setIsFinished(true);
      setTimeout(() => {
        // Scroll smoothly to the section but with less movement
        finishSectionRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center", // Adjust scroll positioning to "center" to scroll less
        });
      }, 100);
    }
  };

  return (
    <section className="bg-black pt-10 pb-10">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white mb-10">
          HOW IT WORKS
        </h2>

        {/* Headings for all steps (visible only on medium and large screens) */}
        <div className="hidden md:grid grid-cols-1 md:grid-cols-3 gap-10 text-center mb-6">
          <h3 className="text-3xlxl font-semibold text-black bg-blue-100 p-2 rounded-3xl ">STEP  1</h3>
          <h3 className="text-3xl font-semibold text-black  bg-red-100 p-2 rounded-3xl ">STEP 2</h3>
          <h3 className="text-3xl font-semibold text-black bg-yellow-100 p-2 rounded-3xl ">STEP 3 </h3>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {/* Conditionally render steps */}
          {currentStep >= 1 && (
            <div className="bg-blue-100 shadow-lg rounded-3xl p-3 text-center transition-transform transform hover:scale-105 h-auto">
              <div className="mb-4 justify-center flex">
                <img src={db1} className="h-72" alt="Step 1" />
              </div>
              <h3 className="text-xl font-semibold text-black mb-2">Choose A Chapter of Your Choice</h3>
              <p className="text-black">
                Each chapter comes with videos, notes, and tests, all crafted according to the latest board syllabus and of the highest quality.
              </p>
            </div>
          )}

          {currentStep >= 2 && (
            <div className="bg-red-100 shadow-lg rounded-3xl p-6 text-center transition-transform transform hover:scale-105 h-auto">
              <div className="mb-4 justify-center flex">
                <img src={db2} className="h-72" alt="Step 2" />
              </div>
              <h3 className="text-xl font-semibold text-black mb-2">Get Points Upon Completion</h3>
              <p className="text-black">
                Upon completing, you’ll earn points in the form of Coins and XP. The amount you receive depends on the difficulty of the chapter.
              </p>
            </div>
          )}

          {currentStep >= 3 && (
            <div className="bg-yellow-100 shadow-lg rounded-3xl p-6 text-center transition-transform transform hover:scale-105 h-auto">
              <div className="mb-4 justify-center flex">
                <img src={db3} className="h-72" alt="Step 3" />
              </div>
              <h3 className="text-xl font-semibold text-black mb-2">Redeem The Points</h3>
              <p className="text-black">
                After accumulating enough points, you can redeem them for exciting gifts. Plus, if you finish in the top 3 on the leaderboard, you’ll earn a grand reward at the end of the month!
              </p>
            </div>
          )}
        </div>

        {/* Next or Finish Button */}
        <div className="text-center mt-10">
          <button
            onClick={nextStep}
            className={`px-6 py-2 h-12 w-36 rounded-3xl transition-transform ${
              currentStep < 3
                ? "bg-indigo-600 text-white text-xl hover:bg-indigo-500"
                : "bg-green-600 text-white text-xl hover:bg-green-500 transform hover:scale-110"
            }`}
          >
            {currentStep < 3 ? "Next Step" : "Finish"}
          </button>
        </div>

        {/* Finish Animation */}
        {isFinished && (
          <div ref={finishSectionRef}>
            <div className="mt-10 text-center text-2xl font-bold text-white animate-bounce">
              🎉 Congratulations! 🎉
              <br />
              Get our app to win rewards this easily
            </div>
            <div className="flex justify-center space-x-3 mt-6">
              <MyComponent />
              <MyComponent2 />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default HowItWorks;
