import React from 'react';

const reviews = [
  {
    id: 1,
    text: "Cubgeon is an absolute game-changer! 🚀 The videos and notes are so well-organized and cover everything from the syllabus. And earning rewards for studying? 🎁 That just makes it so much fun. Highly recommended for all Class 10 students! ⭐",
    author: "Riya Kapoor",
  },
  {
    id: 2,
    text: "I love how Cubgeon combines learning and rewards. The quality of the study materials is fantastic 📚, and the rewards motivate me to study even harder. 💪 The leaderboard feature keeps me competitive too. It's the best app for exam prep! 🏅",
    author: "Aman Shehrawat",
  },
  {
    id: 3,
    text: "The app is really helpful for my Class 10 prep. Videos explain everything clearly, and the notes are super easy to follow. 📖 Getting coins and XP for studying is such a unique way to stay motivated. 🏆 I’ve already redeemed my first gift! 🎉",
    author: "Neha Jain",
  },
  {
    id: 4,
    text: "Cubgeon is my go-to study app! 📝 The notes are aligned with the latest syllabus, and the videos are clear and engaging. What I love the most is the rewards system 🎁, it feels like I’m getting something extra for all my hard work. 🎉",
    author: "K.R. Reddy",
  },
  {
    id: 5,
    text: "Amazing app for Class 10 students! 🌟 The resources are top-notch and updated. The rewards system keeps me coming back for more. 🎁 I wish I had found this earlier. I can’t wait to see what rewards I can unlock next! 🔓",
    author: "Megha Rashmi",
  },
  {
    id: 6,
    text: "Cubgeon has made studying so much more engaging for me. 📚 The fact that I can collect points and win rewards 🎉 for simply studying has changed the way I approach my studies.",
    author: "Raj Malhotra",
  },
  {
    id: 7,
    text: "The variety of resources on Cubgeon is excellent. 🖥️ Whether I want to watch videos or read notes, everything is available and easy to access. The rewards system is a great addition! 🎁",
    author: "Sanya Arora",
  },
  {
    id: 8,
    text: "I was struggling with my Class 10 syllabus until I started using Cubgeon. 🧠 Now, studying feels less stressful, and the rewards motivate me to keep going. 💪 A must-have app for all students! 🎉",
    author: "Aryan Singh",
  },
  {
    id: 9,
    text: "Cubgeon is a lifesaver! 💡 The resources are super helpful, and I love that I can track my progress with coins and XP. 🏆 Plus, the rewards make it even better! 🎁",
    author: "Tara Chatterjee",
  },
  {
    id: 10,
    text: "I highly recommend Cubgeon to all Class 10 students. The study materials are great, and the reward system keeps me engaged. 💼 I've already redeemed two awesome gifts! 🎁",
    author: "Ravi Patel",
  },
];

const cardColors = ['bg-green-100', 'bg-blue-100', 'bg-yellow-100', 'bg-pink-100', 'bg-purple-100'];

const ReviewsSection = () => {
  return (
    <div className="relative overflow-hidden bg-white py-10 mb-4">
      <h2 className="text-3xl font-bold text-center mb-5">WHAT OUR USERS SAY</h2>
      <div className="animate-scroll flex space-x-10 px-4 overflow-hidden">
        {reviews.map((review, index) => (
          <div 
            key={review.id} 
            className={`inline-block p-5 rounded-3xl shadow-md w-72 text-left ${cardColors[index % cardColors.length]}`}
          >
            <p className="text-black break-words leading-relaxed">{review.text}</p>
            <p className="mt-2 font-semibold text-black">- {review.author}</p>
          </div>
        ))}
        {/* Duplicate the reviews for infinite scrolling */}
        {reviews.map((review, index) => (
          <div 
            key={review.id} 
            className={`inline-block p-5 rounded-3xl shadow-md w-72 text-left ${cardColors[index % cardColors.length]}`}
          >
            <p className="text-black break-words leading-relaxed">{review.text}</p>
            <p className="mt-2 font-semibold text-black">- {review.author}</p>
          </div>
        ))}
      </div>

      <style jsx>{`
        .animate-scroll {
          animation: scroll 60s linear infinite; /* Increased duration for slower scroll */
        }

        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%); /* Change this value to scroll further */
          }
        }
      `}</style>
    </div>
  );
};

export default ReviewsSection;