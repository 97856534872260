import { AppStoreButton } from "react-mobile-app-button";

export const MyComponent2 = () => {
  const APKUrl = "https://www.google.com";
//   change link here 

  return (
    <div>
      <AppStoreButton
        url={APKUrl}
        theme={"dark"}
        title=""
        className={"custom-style hover:scale-105"}
      />
    </div>
  );
};